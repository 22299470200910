import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from 'react-router-dom';

import { Protected } from './pages/protected';

import './styles/App.scss';

import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { Error } from './pages/error';
import { Login } from './pages/login';
import { SignIn } from './pages/login/sign-in';
import { ForgotPassword } from './pages/login/forgot-password';
import Logout from './pages/login/logout';
import { SuspenseLoader } from './components/suspense-loader';
import { Requests } from './pages/requests';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalLoader } from './components/global-loader';
import { GlobalMessage } from './components/global-message';
import { mainLoader } from './pages/loader';
import SetToken from './pages/set-token';
import { queryKeyMe } from './hook/use-me';
import produce from 'immer';
import { UserZod } from './schemas/user.zod';

const FormAppraisal = lazy(() => import('./pages/requests/appraisal'));
const FormNewListing = lazy(() => import('./pages/requests/new-listing'));
const Profile = lazy(() => import('./pages/profile'));
const Clients = lazy(() => import('./pages/clients'));
const Properties = lazy(() => import('./pages/properties'));
const Users = lazy(() => import('./pages/users'));
const UsersInvite = lazy(() => import('./pages/users/invite'));
const GoLive = lazy(() => import('./pages/requests/go-live'));
const PriceAdjustment = lazy(() => import('./pages/requests/price-adjustment'));
const OpenHomes = lazy(() => import('./pages/requests/open-homes'));
const WithdrawProperty = lazy(() => import('./pages/requests/withdraw-property'));
const PropertyFallOver = lazy(() => import('./pages/requests/fall-over'));
const UnderOffer = lazy(() => import('./pages/requests/under-offer'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const RegistrationLicence = lazy(() => import('./pages/requests/registration-licence'));
const CPDCertificate = lazy(() => import('./pages/requests/cpd-certificate'));
const TrainingRequests = lazy(() => import('./pages/requests/training'));
const ReportAnIssue = lazy(() => import('./pages/requests/report-an-issue'));
const CompanyDocument = lazy(() => import('./pages/requests/company-document'));
const PropertyBoost = lazy(() => import('./pages/requests/property-boost'));
const VPAOrdering = lazy(() => import('./pages/requests/vpa-ordering'));
const LeaveNotification = lazy(() => import('./pages/requests/leave-notification'));
const Teams = lazy(() => import('./pages/teams'));
const TeamsCreate = lazy(() => import('./pages/teams/create'));
const Copywriting = lazy(() => import('./pages/requests/copywriting'));

import(process.env.REACT_APP_BRAND_STYLE as string);

axios.interceptors.request.use(config => {
  const auth = localStorage.getItem('auth') || sessionStorage.getItem('auth');

  if (auth) {
    // @ts-ignore
    config.headers.Authorization = 'Bearer ' + auth;
  }

  return config;
});
// @ts-ignore
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// axios.defaults.headers.common['Content-Type'] = 'text/plain';
// axios.interceptors.response.use(success => success.data);

function App() {
  console.log('Running App()');

  const queryCache = new QueryCache({
    onError: (error, query) => {
      if (isAxiosError(error)) {
        if (error.response?.data.detail === 'not connected to rex') {
          queryClient.setQueryData<{ data: UserZod }>(
            queryKeyMe,
            produce(draft => {
              if (draft) {
                draft.data.userRex = null;
              }
            })
          );
        }
      }
    }
  });
  const queryClient = new QueryClient({
    queryCache,
    defaultOptions: {
      queries: {
        select(response: AxiosResponse) {
          return response.data;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000
      }
    }
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path={'/'} element={<Outlet />} errorElement={<Error />}>
        <Route path={'set-token'} element={<SetToken />}></Route>

        <Route path={'login'} element={<Login />}>
          <Route index element={<SignIn />} />
          <Route path={'forgot-password'} element={<ForgotPassword />} />
          <Route path={'logout'} element={<Logout />} />
        </Route>

        <Route path={'/error'} element={<Error />} />

        <Route loader={mainLoader(queryClient)} path={'/'} element={<Protected />} shouldRevalidate={() => false}>
          <Route
            path={'dashboard'}
            element={
              <Suspense fallback={<SuspenseLoader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route path={'/'} element={<Navigate to={'/requests'} />} />
          <Route path={'requests/*'}>
            <Route index element={<Requests />} />
            <Route
              path={'appraisal'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <FormAppraisal />
                </Suspense>
              }
            />
            <Route
              path={'new-listing'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <FormNewListing />
                </Suspense>
              }
            />
            <Route
              path={'copywriting'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Copywriting />
                </Suspense>
              }
            />
            <Route
              path={'vpa-ordering'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <VPAOrdering />
                </Suspense>
              }
            />
            <Route
              path={'go-live'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <GoLive />
                </Suspense>
              }
            />
            <Route
              path={'price-adjustment'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <PriceAdjustment />
                </Suspense>
              }
            />
            <Route
              path={'open-homes'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <OpenHomes />
                </Suspense>
              }
            />
            <Route
              path={'withdraw-property'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <WithdrawProperty />
                </Suspense>
              }
            />
            <Route
              path={'fall-over'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <PropertyFallOver />
                </Suspense>
              }
            />
            <Route
              path={'under-offer'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <UnderOffer />
                </Suspense>
              }
            />
            <Route
              path={'property-boost'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <PropertyBoost />
                </Suspense>
              }
            />
            <Route
              path={'leave-notification'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <LeaveNotification />
                </Suspense>
              }
            />
            <Route
              path={'registration-licence'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <RegistrationLicence />
                </Suspense>
              }
            />
            <Route
              path={'cpd-certificate'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <CPDCertificate />
                </Suspense>
              }
            />
            <Route
              path={'company-document'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <CompanyDocument />
                </Suspense>
              }
            />
            <Route
              path={'training'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <TrainingRequests />
                </Suspense>
              }
            />
            <Route
              path={'report-an-issue'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <ReportAnIssue />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={'profile'}
            element={
              <Suspense fallback={<SuspenseLoader />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={'clients'}
            element={
              <Suspense fallback={<SuspenseLoader />}>
                <Clients />
              </Suspense>
            }
          />
          <Route
            path={'properties'}
            element={
              <Suspense fallback={<SuspenseLoader />}>
                <Properties />
              </Suspense>
            }
          />
          <Route path={'users'}>
            <Route
              index
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Users />
                </Suspense>
              }
            />
            <Route
              path={':id'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <UsersInvite />
                </Suspense>
              }
            />
          </Route>
          <Route path={'teams'}>
            <Route
              index
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Teams />
                </Suspense>
              }
            />
            <Route
              path={':id'}
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <TeamsCreate />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <GlobalLoader>
        <GlobalMessage>
          <RouterProvider router={router} />
        </GlobalMessage>
      </GlobalLoader>
    </QueryClientProvider>
  );
}

export default App;
